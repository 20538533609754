// Used to prevent repetitive annoyance
import jwt from 'jsonwebtoken';
import _ from 'lodash';
import { stringify } from 'query-string';
import { setup_main_socket_ac } from './room.ac.js';
import { open_snack_ac } from './snack.ac.js';
//import { notif_worker_init } from './web_workers.ac.js';

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
// window.ROLLBAR_CONFIG = {
//   environment: process.env.REACT_APP_IS_DEV === 'yes' ? 'dev' : 'production',
//   accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
//   captureUncaught: true,
//   captureUnhandledRejections: true,
//   retryInterval: 5000,
//   code_version: process.env.REACT_APP_VER,
//   guess_uncaught_frames: true,
//   scrubTelemetryInputs: true,
//   autoInstrument: {
//     network: true,
//     log: false,
//     dom: false,
//     navigation: true,
//     connectivity: false,
//   },
// };

let missingAuthError = new Error(
  'No cached authentication data found. Please call login() first.'
);
missingAuthError.code = 'AUTH_MISSING';
missingAuthError.name = 'AUTH_MISSING';

let refreshExpError = new Error('Refresh token expired');
refreshExpError.code = 'AUTH_REFRESH_EXPIRED';
refreshExpError.name = 'AUTH_REFRESH_EXPIRED';

let accessExpError = new Error('Access token expired');
accessExpError.code = 'AUTH_ACCESS_EXPIRED';
accessExpError.name = 'AUTH_ACCESS_EXPIRED';

async function sa_check(forceRefresh) {
  let shouldRefresh = false;
  let auth = window.localStorage.getItem(process.env.REACT_APP_SA_CACHE_KEY);

  if (!auth) {
    throw missingAuthError;
  }

  auth = JSON.parse(auth);

  // Refresh token expired, need to log the user out
  if (Math.floor(Date.now() / 1000) >= auth.refresh_exp) {
    console.log('refresh token expired, logging out');
    window.localStorage.clear();
    window.location.reload();
    return;
  }

  // Access token expired, need to refresh
  if (Math.floor(Date.now() / 1000) >= auth.access_exp) {
    shouldRefresh = true;
  }

  if (shouldRefresh || forceRefresh) {
    try {
      await sa_refresh(); //stores it back into localStorage
      auth = window.localStorage.getItem(process.env.REACT_APP_SA_CACHE_KEY);
      auth = JSON.parse(auth);
      console.log('refreshed access token');
    } catch (error) {
      console.log('error caugh after sa_refresh attempt', error);
      shouldRefresh = false;
      throw error;
    }
  }
  let pkg = Object.assign({ didRefresh: shouldRefresh, ...auth });
  return pkg;
}

function sa_refresh() {
  let auth = window.localStorage.getItem(process.env.REACT_APP_SA_CACHE_KEY);
  if (!auth) throw missingAuthError;
  auth = JSON.parse(auth);

  console.log('Refreshing sa access token');
  let postData = {
    accessToken: auth.access_token,
    refreshToken: auth.refresh_token,
  };
  return fetch(
    // `${process.env.REACT_APP_WOS_API_URL}/api/superauth/refreshtokens`,
    `${process.env.REACT_APP_WOS_API_URL}/api/superauth/refresh`,
    {
      method: 'POST',
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: { 'Content-Type': 'application/json' },
      redirect: 'follow', // manual, *follow, error
      body: JSON.stringify(postData),
    }
  )
    .then((response) => {
      //evaluate response (fetch does not throw on 400 errors)
      //console.log(response )
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      auth.access_token = result.superAuthResponse.access_token;
      auth.access_exp = result.superAuthResponse.access_exp;
      window.localStorage.setItem(
        process.env.REACT_APP_SA_CACHE_KEY,
        JSON.stringify(auth)
      );
      return;
    })
    .catch((error) => {
      throw error;
    });
}

const decodedJWT = () => {
  let auth = window.localStorage.getItem(process.env.REACT_APP_SA_CACHE_KEY);
  if (!auth) throw missingAuthError;
  auth = JSON.parse(auth);
  let decoded = jwt.decode(auth.access_token, { complete: true });
  decoded.payload.data = JSON.parse(decoded.payload.data);
  return decoded;
};

window.decodedJWT = decodedJWT;

if (
  process.env.REACT_APP_IS_DEV === 'yes' &&
  process.env.REACT_APP_DEV_ACCESS_TOKEN_KEY
) {
  window._DEV_parseAuthUser = () => {
    let auth = window.localStorage.getItem(process.env.REACT_APP_SA_CACHE_KEY);
    if (!auth) return;

    auth = JSON.parse(auth);

    let token = jwt.verify(
      auth.access_token,
      process.env.REACT_APP_DEV_ACCESS_TOKEN_KEY
    );

    const decoded = JSON.parse(token.data);
    return decoded;
  };

  window._DEV_updateAccessToken = (newUser) => {
    try {
      let auth = window.localStorage.getItem(
        process.env.REACT_APP_SA_CACHE_KEY
      );
      if (!auth) return;

      auth = JSON.parse(auth);
      auth.access_token = jwt.sign(
        {
          exp: Math.floor((Date.now() + 10000000000) / 1000),
          data: JSON.stringify(newUser),
        },
        process.env.REACT_APP_DEV_ACCESS_TOKEN_KEY
      );

      window.localStorage.setItem(
        process.env.REACT_APP_SA_CACHE_KEY,
        JSON.stringify(auth)
      );
    } catch (e) {
      console.log(e);
    }
  };
}

const mock_res = (kind = 'get', path, ...rest) => {
  return new Promise((resolve) => {
    try {
      const [, , src, key] = path.split('/');

      if (src && key) {
        const file = require(`../mocks/${src}.js`);
        const data = file[key] || {};

        const node = data.hasOwnProperty(kind) ? data[kind] : data;

        resolve(typeof node === 'function' ? node(...rest) || {} : node);
      }

      resolve({});
    } catch (_) {
      resolve({});
    }
  });
};

function post_ink(path, postData) {
  let targetUrl = process.env.REACT_APP_INK_API_URL + path;
  postData['x-client-code'] = '38ab1cd216b0643c1.' + new Date().getTime();
  return window.sch
    .sa_check()
    .then((saPkg) => {
      return fetch(targetUrl, {
        method: 'POST',
        mode: 'cors',
        //cache: 'no-cache',
        //credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + saPkg.access_token,
        },
        body: JSON.stringify(postData),
      });
    })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      if (res.ok === false || res.error) throw new Error(res.error);
      return res;
    })
    .catch((err) => {
      throw err;
    });
}

function get(path, params = {}, signal) {
  if (path.startsWith('/mock')) {
    return mock_res('get', path, params);
  }

  return window.sch
    .sa_check()
    .then((saPkg) => {
      let targetUrl = process.env.REACT_APP_WOS_API_URL + path;
      if (Object.keys(params).length) {
        targetUrl += `?${stringify(params)}`;
      }
      return fetch(targetUrl, {
        method: 'GET',
        mode: 'cors', // no-cors, cors, *same-origin
        ...(signal && signal instanceof AbortSignal ? { signal } : {}),
        headers: {
          'Content-Type': 'application/json',
          'X-SCH-LVNSN': 'jIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.4.6.0',
          Authorization: 'Bearer ' + saPkg.access_token,
        },
        params,
      });
    })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      if (res.ok === false || res.error) throw new Error(res.error);
      return res;
    })
    .catch((err) => {
      throw err;
    });
}

function download_as_csv(path, filename) {
  return window.sch
    .sa_check()
    .then((saPkg) => {
      let targetUrl = process.env.REACT_APP_WOS_API_URL + path;
      return fetch(targetUrl, {
        method: 'GET',
        mode: 'cors', // no-cors, cors, *same-origin
        headers: {
          'Content-Type': 'application/json',
          'X-SCH-LVNSN': 'jIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.4.6.0',
          Authorization: 'Bearer ' + saPkg.access_token,
        },
      });
    })
    .then((res) => {
      if (res.ok === false) {
        throw new Error('Failed download');
      }
      return res.blob();
    })
    .then((blob) => {
      let a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = filename;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    })
    .catch((err) => {
      console.error(err);
    });
}

function download(path, filename, returnDataUrl = false) {
  return window.sch
    .sa_check()
    .then((saPkg) => {
      let targetUrl = process.env.REACT_APP_WOS_API_URL + path;
      return fetch(targetUrl, {
        method: 'GET',
        mode: 'cors', // no-cors, cors, *same-origin
        headers: {
          'Content-Type': 'application/json',
          'X-SCH-LVNSN': 'jIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.4.6.0',
          Authorization: 'Bearer ' + saPkg.access_token,
        },
      });
    })
    .then((res) => {
      if (res.ok === false) {
        throw new Error('Failed download');
      }
      console.log('res', res);
      //check if res is a blob
      if (res.headers.get('content-type').search('application/pdf') === -1) {
        return res.json();
      }

      return res.blob();
    })
    .then((blob) => {
      //check if blob is actually a JSON object
      if (blob.signedUrl) {
        //blob should be a JSON object
        console.log('blob is not a blob', blob);
        return blob;
      }

      //the blob might be a
      //this blob needs to be base64 decoded
      if (returnDataUrl) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = function () {
            console.log(reader);
            let decoded = atob(reader.result.split(',')[1]);
            console.log('decoded', decoded);

            //decoded is a decoded base64 string, now create a data url out of it
            let dataUrl = 'data:application/pdf;base64,' + decoded;
            return resolve(dataUrl);
          };
          reader.readAsDataURL(blob);
          return;
        });
      }

      //legacy used by other components
      let a = document.createElement('a');
      console.log(blob);
      a.href = window.URL.createObjectURL(blob);
      a.download = filename;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    })
    .catch((err) => {
      console.error(err);
    });
}

function fetchFileBlob(path, params = {}) {
  return window.sch
    .sa_check()
    .then((saPkg) => {
      let targetUrl = process.env.REACT_APP_WOS_API_URL + path;
      return fetch(targetUrl, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        headers: {
          'Content-Type': 'application/json',
          'X-SCH-LVNSN': 'jIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.4.6.0',
          Authorization: 'Bearer ' + saPkg.access_token,
        },
        body: JSON.stringify(params),
      });
    })
    .then((res) => {
      if (res.ok === false) {
        throw new Error('Failed download');
      }
      return res.blob();
    })
    .catch((err) => {
      console.error(err);
    });
}

function post(path, postData = {}, isFormData = false, signal) {
  if (path.startsWith('/mock')) {
    return mock_res('post', path, postData);
  }

  let fetchResult;
  return window.sch
    .sa_check()
    .then((saPkg) => {
      let targetUrl = process.env.REACT_APP_WOS_API_URL + path;
      const xClientCode = '38ab1cd216b0643c1.' + new Date().getTime();
      if (isFormData) {
        postData.append('x-client-code', xClientCode);
      } else {
        postData['x-client-code'] = xClientCode;
      }
      return fetch(targetUrl, {
        ...(signal && signal instanceof AbortSignal ? { signal } : {}),
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        headers: {
          'X-SCH-LVNSN': 'jIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.4.6.0',
          Authorization: 'Bearer ' + saPkg.access_token,
          ...(isFormData
            ? {}
            : {
                'Content-Type': 'application/json',
              }),
        },
        body: isFormData ? postData : JSON.stringify(postData),
        maxBodyLength: Infinity,
      });
    })
    .then((res) => {
      fetchResult = res;
      return res.json();
    })
    .then((result) => {
      if (!fetchResult.ok) throw new Error(result.error);
      return result;
    })
    .catch((err) => {
      console.error('post wos api faield', err);
      throw err;
    });
}

// AKu - added for error handling
// - REMOVED, rie 8-21, merged with post above

window.sch = {
  sa_check,
  sa_refresh,
  decodedJWT,
  post,
  get,
  post_ink,
  download,
  download_as_csv,
  fetchFileBlob,
};

function requestMe_ac() {
  //console.log("requestAccountDocs_ac fired",accountIdn)
  return {
    type: 'REQUEST_ME',
  };
}

function receivedMe_ac(meDoc) {
  //console.log("receivedAccountDocs_ac fired", accountIdn, accountDocs.length)
  return {
    type: 'RECEIVED_ME',
    meDoc,
  };
}

function failed_receivedMe_ac(errPkg) {
  return {
    type: 'FAILED_RECEIVED_ME',
    error: errPkg.error,
    from: errPkg.from,
  };
}

export function toggleFilter_ac(openOrClose) {
  return (dispatch) => {
    //params passed in by thunk middleware
    dispatch({ type: 'TOGGLE_FILTER', open: openOrClose });
  };
}

export function setTitle_ac(title) {
  return (dispatch) => {
    //params passed in by thunk middleware
    dispatch({ type: 'SET_TITLE', title });
  };
}

export function logout_ac(googleUserObject) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    window.localStorage.removeItem(process.env.REACT_APP_SA_CACHE_KEY);
    dispatch({ type: 'LOGOUT_ME' });
  };
}

//this is only called AFTER google login button was successful
//and window.googleUserObject has been set
export function login_with_superauth_ac() {
  return (dispatch) => {
    dispatch(requestMe_ac());
    let postData = {
      //idToken: window.googleUserObject.tokenId,
      idToken: window.googleLoginResponse.credential,
      isIBreatheCenters: false,
    };
    postData['x-client-code'] = '38ab1cd216b0643c1.' + new Date().getTime();
    return fetch(`${process.env.REACT_APP_WOS_API_URL}/api/superauth/login`, {
      method: 'POST',
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache',
      headers: { 'Content-Type': 'application/json' },
      redirect: 'follow', // manual, *follow, error
      body: JSON.stringify(postData),
    })
      .then((response) => {
        //evaluate response (fetch does not throw on 400 errors)
        //console.log(response )
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((result) => {
        console.log('me results', result);
        window.localStorage.setItem(
          process.env.REACT_APP_SA_CACHE_KEY,
          JSON.stringify(result.superAuthResponse)
        );
        dispatch(requestGmailScopes());
        return;
      })
      .catch((error) => {
        console.log('GOT ERROR FROM login_with_superauth_ac');
        console.error('error:', error);
        dispatch(failed_receivedMe_ac({ error, from: 'SC-AUTH' }));
        return;
      });
  };
}

export function every_landing_ac() {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch(requestMe_ac());
    return window.sch
      .sa_check() //should throw if no valid token in localStorage
      .then(async (saPkg) => {
        dispatch({ type: 'STORE_SA_TOKEN', saToken: saPkg.access_token });
        let decoded = jwt.decode(saPkg.access_token, { complete: true });
        let tmpUserObjectFromLocalStorage = JSON.parse(decoded.payload.data);
        //dispatch(receivedMe_ac({ user: JSON.parse(decoded.payload.data) }))
        //dispatch(validate_gapi_ac(googleUserObject))
        return window.sch.post('/api/gapi/validate/onlanding', {
          email: tmpUserObjectFromLocalStorage.mail,
          uv_ver: process.env.REACT_APP_VER || '0.0.0',
        });
      })
      .then((result) => {
        dispatch({ type: 'SET_UV_API_ENV', uv_api_env: result.uv_api_env });

        if (result.valid === false) {
          //dispatch(requestGmailScopes())
          throw new Error('Logged in but scopes missing');
        }

        dispatch(receivedMe_ac({ user: result.user }));
        dispatch(lookups_ac());

        dispatch({ type: 'GOT_GAPI_RESULT', valid: true });

        //temporarily disabled until DASH is recovered
        dispatch(setup_main_socket_ac());

        if (window.location.pathname.search(/dfa_no_app_bar/) !== -1) {
          console.log('skip notif worker when in no appbar');
          return;
        }
        //disabled 5-31-2023 - no longer needed
        //dispatch(notif_worker_init('initNotifRecords'));
        return;
      })
      .catch((error) => {
        console.error(error);

        let from = 'UV-API-ONLANDING';
        if (error instanceof TypeError) {
          from += ' - A system issue prevented network access';
        }

        dispatch(failed_receivedMe_ac({ error, from }));
        return;
      });
  };
}

function requestGmailScopes() {
  return (dispatch, getState) => {
    dispatch({ type: 'GAPI_VALIDATING' });
    let gmailScopes = SCOPES_DESIRED.join(' ');

    let paramsToInitCodeClient = {
      client_id: process.env.REACT_APP_DOCFLOW_LOGIN_CLIENT_ID,
      scope: gmailScopes,
      ux_mode: 'popup',
      callback: (gResponse) => {
        console.log(gResponse);

        return window.sch
          .post('/api/google/grantscopes', gResponse)
          .then((result) => {
            dispatch({ type: 'GOT_GAPI_RESULT', valid: true });
            dispatch(every_landing_ac());
            return;
          })
          .catch((grantErr) => {
            console.error('grantOffLineAccess Error', grantErr);
            alert('ARE YOU SURE YOUR POPUPS ARE NOT BLOCKED?');
            dispatch({ type: 'GOT_GAPI_RESULT', valid: false });
            return;
          });
      },
    };

    const client = window.google.accounts.oauth2.initCodeClient(
      paramsToInitCodeClient
    );
    return client.requestCode();
  };
}

export function lookups_ac() {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    if (getState().lookups.isFetching) {
      console.log('..gapi is already fetching');
      return;
    }

    dispatch({ type: 'FETCHING_LOOKUPS' });
    window.sch
      .post_ink('/api/ink_env', {})
      .then((result) => {
        dispatch({ type: 'SET_INK_API_ENV', ink_api_env: result.ink_api_env });
        return window.sch.get('/api/fullcache');
      })
      .then((result) => {
        dispatch({ type: 'GOT_INK_FULLCACHE', pkg: result });
        return;
      })
      .catch((error) => {
        dispatch({ type: 'FAILED_GOT_INK_FULLCACHE', error });
        return;
      });
  };
}

export function toggleUserPref_ac(params) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware

    let user = { ...getState().me.user };
    window.sch
      .post('/api/user/pref/update', params)
      .then((result) => {
        refresh_user_profile_ac()(dispatch, getState);

        dispatch({
          type: 'GOT_UPDATED_USER',
          user: {
            ...user,
            defaults: result.defaults,
          },
        });
      })
      .catch(() => {
        return;
      });
  };
}

export function syncUserFilters_ac(
  filterKey,
  filterValue,
  { before, after, onSuccess, onError } = {}
) {
  return (dispatch, getState) => {
    if (before) {
      before();
    }
    let user = { ...getState().me.user };
    window.sch
      .post('/api/user/patch_filters', {
        filterKey,
        filterValue,
      })
      .then(() => {
        _.set(user, `filters.${filterKey}`, filterValue);
        dispatch({
          type: 'GOT_UPDATED_USER',
          user,
        });

        if (onSuccess) {
          onSuccess();
        }
        if (after) {
          after();
        }
      })
      .catch((e) => {
        if (onError) {
          onError();
        }
        if (after) {
          after();
        }
        console.error('Error while syncing user filters', e);
        return;
      });
  };
}

export function upsertUserFilter_ac(
  filterKey,
  filterName,
  filterValue,
  { onSuccess, onError } = {}
) {
  return (dispatch, getState) => {
    window.sch
      .post('/api/user/patch_filters/upsert', {
        filterKey,
        filterName,
        filterValue,
      })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((e) => {
        if (onError) {
          onError();
        }
        console.error('Error while syncing user filters', e);
        return;
      });
  };
}

export function updateStoreUser_ac(newUser) {
  return (dispatch) => {
    dispatch({
      type: 'GOT_UPDATED_USER',
      user: newUser,
    });
  };
}

const SCOPES_DESIRED = [
  'https://mail.google.com/',
  'https://www.googleapis.com/auth/gmail.compose',
  'https://www.googleapis.com/auth/gmail.send',
  'https://www.googleapis.com/auth/gmail.labels',
  'https://www.googleapis.com/auth/drive',
  'https://www.googleapis.com/auth/drive.file',
  'https://www.googleapis.com/auth/drive.appdata',
  'https://www.googleapis.com/auth/spreadsheets',
];

export function refresh_user_profile_ac() {
  return async (dispatch, getState) => {
    let lsAuth = window.localStorage.getItem(
      process.env.REACT_APP_SA_CACHE_KEY
    );
    if (!lsAuth) return;

    lsAuth = JSON.parse(lsAuth);

    try {
      // const dfRes = await window.sch.post('/api/superauth/refreshtokens', {
      //   accessToken: lsAuth.access_token,
      //   refreshToken: lsAuth.refresh_token,
      // });

      //directly access the SA endpoint now on uv-api
      const dfRes = await window.sch.post('/api/refresh', {
        // accessToken: lsAuth.access_token,
        refreshToken: lsAuth.refresh_token,
      });

      // const { access_token, access_exp } = dfRes.superAuthResponse || {};
      const { access_token, access_exp } = dfRes || {};
      const { user } = dfRes;

      if (access_token) {
        console.log(
          '\nAccess token refreshed from uv-api /api/superauth/refreshtokens - storing in localStorage'
        );
        window.localStorage.setItem(
          process.env.REACT_APP_SA_CACHE_KEY,
          JSON.stringify({
            ...lsAuth,
            access_token,
            access_exp,
          })
        );

        const _user = window.decodedJWT().payload.data;
        const teamaccess = _.uniq([
          ..._user.teamq,
          ..._user.supervisor_of,
          ..._user.manager_of,
        ]).sort();

        if (user === undefined) {
          dispatch(
            open_snack_ac({
              variant: 'error',
              message: 'User details not provided by refresh results',
            })
          );
          return;
        }

        dispatch({ type: 'RECEIVED_ME_REFRESH', user });
        dispatch(
          open_snack_ac({
            variant: 'info',
            message:
              'Teams: ' +
              teamaccess.join(', ') +
              ' - Roles:' +
              _user.roles.join(', '),
          })
        );
      } else {
        throw new Error('Token not obtained!');
      }
    } catch (error) {
      console.log('Error while refreshing token. skipping refresh..', error);
      dispatch(
        open_snack_ac({
          variant: 'error',
          message:
            'Failed refreshing your user profile - You must log out and log in',
        })
      );
      return;
    }
  };
}
