import {
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
async function uploadToS3(file, signedUrl) {
  // Prepare headers for CORS
  const headers = new Headers({
    'Content-Type': file.type,
    Origin: window.location.origin, // This should match your app's origin for CORS
  });

  // Here we set up the fetch options for the upload
  const options = {
    method: 'PUT',
    headers: headers,
    body: file,
    mode: 'cors', // Explicitly setting this for cross-origin requests
  };

  try {
    const response = await fetch(signedUrl, options);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    console.log('File uploaded successfully to S3.');
    return true;
  } catch (error) {
    console.error('Failed to upload file to S3:', error);
    return false;
  }
}

const styles = (theme) => ({
  dropZone: {
    border: `2px dashed ${theme.palette.primary.main}`,
    padding: 38,
    textAlign: 'center',
    cursor: 'pointer',
  },
  list: {
    marginTop: 3,
  },
  item: {
    padding: 3,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  button: {
    margin: 4,
  },
});

const PDFDropZone = ({ classes }) => {
  const [files, setFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState('idle');

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files).slice(0, 10); // Limit to 10 files
    const validatedFiles = newFiles.map((file) => ({
      file: file,
      name: file.name,
      status: file.type === 'application/pdf' ? 'ready for upload' : 'invalid',
    }));
    setFiles((prevFiles) => [...prevFiles, ...validatedFiles]);
  };

  const handleUpload = async () => {
    setUploadStatus('IN progress');

    for (let item of files) {
      if (item.status === 'ready for upload') {
        item.status = '...begin upload';
        setFiles([...files]); // Update UI to show upload initiation for each file

        try {
          const endpoint = '/api/s3/object/getsignedurl_for_upload';
          const params = {
            bucket: 'sch.filebound',
            s3key: 'PreFaxIn/UserUploaded/' + item.name,
            contentType: 'application/pdf',
            purpose: 'fba',
          };
          const res = await window.sch.post(endpoint, params);

          if (res.upload_url) {
            // Here you would initiate the upload, but for now, we'll simulate it:
            await uploadToS3(item.file, res.upload_url);
            item.status = 'uploading';
            setFiles([...files]);

            // Simulating upload delay
            await new Promise((resolve) => setTimeout(resolve, 1000));
            item.status = 'uploaded';
          } else {
            item.status = 'failed';
          }
          setFiles([...files]);
        } catch (error) {
          console.error('Upload failed:', error);
          item.status = 'failed';
        }
      }
    }
    setUploadStatus('idle');
  };

  const clearFiles = () => {
    setFiles([]);
  };

  return (
    <Paper>
      <div
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        className={classes.dropZone}>
        <Typography variant="h6">Drop PDF files here for Indexing</Typography>
      </div>
      <List className={classes.list}>
        {files.map((file, index) => (
          <ListItem key={index} className={classes.item}>
            <ListItemText primary={file.name} secondary={file.status} />
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        disabled={
          uploadStatus === 'IN progress' ||
          !files.some((f) => f.status === 'ready for upload')
        }
        className={classes.button}>
        Upload
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={clearFiles}
        className={classes.button}>
        Clear
      </Button>
    </Paper>
  );
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export const FBUploadFiles = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PDFDropZone))
);
