import {
  Button,
  Chip,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { open_snack_ac } from '../../../actions/snack.ac';
import GenericUserSearch from '../../common/GenericUserSearch';
import { Loading } from '../../HourlyWos/components';
import PasswordInput from './PasswordInput';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const API_ENDPOINTS = {
  GET_PLACES: '/api/dt/places',
  GET_DRIVER_DETAIL_BY_ID: '/api/dt/drivers',
  UPDATE_DRIVER_DETAIL: '/api/dt/update_driver',
  CREATE_DRIVER_DETAIL: '/api/driver/create_driver',
  UPDATE_DRIVER_PASSWORD: '/api/driver/update_password',
};

const styles = () => ({
  form: {
    width: '100%',
    marginBottom: 10,
  },
  submitButton: {
    marginTop: 10,
    alignSelf: 'center',
  },
  tabContent: {
    marginTop: 20,
    width: '85%',
  },
  label: {
    position: 'absolute',
    padding: '18.5px 14px',
    fontSize: 14,
  },
  labelShrink: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    position: 'absolute',
    top: 0,
    left: 20,
    zIndex: 1,
    background: 'white',
    width: 60,
    textAlign: 'center',
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const access_options = ['COURIER', 'RT', 'ADMIN', 'PST'];

const roles = [
  'admin',
  'inactive',
  'dispatcher',
  'oncall',
  'courier',
  'no_phone',
  'driver',
];

export async function toggleDriverActive(
  driver_id,
  active,
  { before, after, onSuccess, onError } = {}
) {
  if (before) before();
  try {
    const response = await window.sch.post('/api/dt/drivers/switch_active', {
      driver_id,
      active,
    });

    if (response.error) {
      console.error(response.error);
      if (onError) onError(new Error(response.error));
    }

    if (onSuccess) onSuccess();
  } catch (err) {
    if (onError) onError(err);
    console.error(err);
  }

  if (after) after();
}

const EditDriverForm = (props) => {
  const { classes, open_snack_ac } = props;

  const [dc, setDc] = useState([]);
  const [res, setRes] = useState(false);
  const [error, setError] = useState('');
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [driverDetail, setDriverDetail] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isShowPasswordForm, setIsShowPasswordForm] = useState(false);
  const [pwData, setPwData] = useState({
    password: '', // New password
    confirmPassword: '', // Confirm password
  });

  const [isChangingVisibility, setIsChangingVisibility] = useState(false);
  const driverId = props.location.pathname.split('/').pop();

  const isCreateDriver =
    props.location.pathname.split('/').pop() === 'create-driver';

  useEffect(() => {
    const defaultFormData = isCreateDriver
      ? { roles: ['oncall', 'driver'] }
      : driverDetail;

    setFormData(defaultFormData);
  }, [driverDetail, isCreateDriver]);

  useEffect(() => {
    if (!isCreateDriver) {
      fetchDriverDetailById();
    }
    fetchPlaces();
    // eslint-disable-next-line
  }, []);

  const fetchPlaces = async () => {
    try {
      const { place } = await window.sch.get(API_ENDPOINTS.GET_PLACES);
      setDc(place);
    } catch (err) {
      handleApiError('Error fetching locations');
    }
  };

  const fetchDriverDetailById = async () => {
    try {
      setIsLoading(true);
      const { driver } = await window.sch.get(
        `${API_ENDPOINTS.GET_DRIVER_DETAIL_BY_ID}/${driverId}`
      );

      setDriverDetail(driver);
    } catch (err) {
      handleApiError('Error fetching driver details');
    } finally {
      setIsLoading(false);
    }
  };

  async function updateDriverDetail(formData) {
    const data = await window.sch.post(API_ENDPOINTS.UPDATE_DRIVER_DETAIL, {
      driver: formData,
    });
    return data;
  }

  async function createDriver(formData) {
    const data = await window.sch.post(
      API_ENDPOINTS.CREATE_DRIVER_DETAIL,
      formData
    );
    return data;
  }
  async function updateDriverPassword(payload) {
    const data = await window.sch.post(
      API_ENDPOINTS.UPDATE_DRIVER_PASSWORD,
      payload
    );
    return data;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'roles') {
      setMenuOpen(false);
    }

    if (name === 'email') {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      setErrors((prev) => ({
        ...prev,
        email: isValidEmail ? '' : 'Invalid email address',
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const submitDriverDetail = async (formData, type) => {
    const message = isCreateDriver
      ? 'Driver created sucessfully'
      : `${formData.driver_nm} detail updated sucessfully`;

    try {
      setIsSubmitting(true);
      if (isCreateDriver) {
        const res = await createDriver({ [type]: formData });
        setRes(res);
        setFormData({ roles: ['oncall', 'driver'] });
      } else {
        await updateDriverDetail(formData);
        props.history.push('/admin/dtrack/drivers');
      }

      props.open_snack_ac({
        variant: 'success',
        message,
      });
    } catch (err) {
      setIsSubmitting(false);
      props.open_snack_ac({
        variant: 'error',
        message: err.message,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const submit = () => {
    if (!validateForm()) {
      return;
    }
    const body = {
      ...formData,
      driver_id: parseInt(formData.driver_id),
    };
    submitDriverDetail(body, 'driver');
  };

  const createDriverInSch = () => {
    const data = {
      dc_id: formData.dc_id,
      is_rt: formData.is_rt,
      _id: formData._id,
    };
    submitDriverDetail(data, 'schUser');
  };

  const handleDeleteRole = (role) => {
    const newRoles = formData.roles.filter((r) => r !== role);

    setFormData({
      ...formData,
      roles: newRoles,
    });
  };

  const handleApiError = (message) => {
    open_snack_ac({
      variant: 'error',
      message,
    });
  };

  const validateForm = () => {
    const requiredFields = {
      driver_id: 'Driver’s HDMS Op ID is required',
      _id: 'ID is required',
      driver_nm: 'Name is required',
      dc_id: 'Location is required',
      email: 'Email is required',
      ph: 'Phone is required',
      access: 'Access is required',
    };

    const newErrors = Object.entries(requiredFields).reduce(
      (errors, [field, errorMessage]) => {
        if (!formData[field]) {
          errors[field] = errorMessage;
        }
        return errors;
      },
      {}
    );

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handlePwChange = (e) => {
    const { name, value } = e.target;

    setPwData((prev) => {
      const updatedData = {
        ...prev,
        [name]: value,
      };

      // Validate password length
      if (updatedData.password && updatedData.password.length < 8) {
        setError('Password must be at least 8 characters long');
      }
      // Validate password match
      else if (
        updatedData.confirmPassword &&
        updatedData.password !== updatedData.confirmPassword
      ) {
        setError('Passwords do not match');
      } else {
        setError('');
      }

      return updatedData;
    });
  };

  const handleVisibilityChange = (disable = true) => {
    setIsChangingVisibility(true);

    toggleDriverActive(
      formData.driver_id,

      !disable,
      {
        before: () => setIsChangingVisibility(true),
        onSuccess: () => {
          setDriverDetail((prev) =>
            disable
              ? {
                  ...prev,
                  disabled: true,
                  roles: [...prev.roles, 'inactive'],
                }
              : {
                  ...prev,
                  disabled: false,
                  roles: prev.roles.filter((role) => role !== 'inactive'),
                }
          );
          props.open_snack_ac({
            variant: 'success',
            message: `Driver ${disable ? 'disabled' : 'enabled'} successfully`,
          });
        },
        onError: (e) => {
          console.log(e);
          props.open_snack_ac({
            variant: 'error',
            message: `Failed to ${disable ? 'disable' : 'enable'} driver`,
          });
        },
        after: () => {
          setIsChangingVisibility(false);
        },
      }
    );
  };

  const handleUpdateDriver = async (driverId, password, confirmPassword) => {
    if (!password || password.length < 8) {
      props.open_snack_ac({
        variant: 'error',
        message: 'Password must be at least 8 characters long',
      });
      return;
    }

    if (password !== confirmPassword) {
      props.open_snack_ac({
        variant: 'error',
        message: 'Passwords do not match',
      });
      return;
    }

    try {
      setIsSubmitting(true);

      const passwordPayload = {
        driverId: parseInt(driverId),
        newPassword: password,
      };
      const res = await updateDriverPassword(passwordPayload);

      // props.history.push('/admin/dtrack/drivers');
      setPwData({
        password: '',
        confirmPassword: '',
      });

      props.open_snack_ac({
        variant: 'success',
        message: res.message || 'Password updated successfully',
      });
    } catch (err) {
      props.open_snack_ac({
        variant: 'error',
        message: err.message || 'Failed to update password',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePwSubmit = () => {
    handleUpdateDriver(
      formData.driver_id,
      pwData.password,
      pwData.confirmPassword
    );
  };

  const renderTabContent = (res) => (
    <>
      {res?.setPassword ? (
        renderPasswordMessage(res.setPassword)
      ) : (
        <Grid container spacing={8} className={classes.tabContent}>
          <Grid
            item
            xs={6}
            style={{
              height: '80vh',
              overflowY: 'auto',
            }}>
            <Grid container spacing={8}>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  required
                  label="Driver’s HDMS Op ID"
                  name="driver_id"
                  variant="outlined"
                  onChange={handleChange}
                  disabled={!isCreateDriver}
                  helperText={errors.driver_id}
                  value={formData.driver_id || ''}
                  error={Boolean(errors.driver_id)}
                />
              </Grid>
              {!isCreateDriver && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Phone Model"
                      name="phone_model"
                      variant="outlined"
                      onChange={handleChange}
                      value={formData.phone_model || ''}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Phone OS"
                      name="phone_os"
                      variant="outlined"
                      onChange={handleChange}
                      value={formData.phone_os || ''}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={isCreateDriver ? 8 : 6}>
                <TextField
                  fullWidth
                  label="ID"
                  name="_id"
                  required
                  variant="outlined"
                  onChange={handleChange}
                  helperText={errors._id}
                  disabled={!isCreateDriver}
                  value={formData._id || ''}
                  error={Boolean(errors._id)}
                />
              </Grid>
              {!isCreateDriver && (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="UID"
                    name="u_id"
                    variant="outlined"
                    onChange={handleChange}
                    disabled={!isCreateDriver}
                    value={formData.u_id || ''}
                  />
                </Grid>
              )}

              <Grid item xs={8}>
                <TextField
                  fullWidth
                  required
                  label="Name"
                  name="driver_nm"
                  variant="outlined"
                  onChange={handleChange}
                  helperText={errors.driver_nm}
                  value={formData.driver_nm || ''}
                  error={Boolean(errors.driver_nm)}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  required
                  fullWidth
                  name="email"
                  label="Email"
                  variant="outlined"
                  onChange={handleChange}
                  helperText={errors.email}
                  value={formData.email || ''}
                  error={Boolean(errors.email)}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  required
                  fullWidth
                  name="ph"
                  label="Phone"
                  variant="outlined"
                  helperText={errors.ph}
                  onChange={handleChange}
                  value={formData.ph || ''}
                  error={Boolean(errors.ph)}
                />
              </Grid>

              <Grid item xs={8}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.is_rt || false}
                      onChange={(e) => {
                        handleChange({
                          target: {
                            name: 'is_rt',
                            value: e.target.checked,
                          },
                        });
                      }}
                      value={'is_rt'}
                    />
                  }
                  label={'Is RT'}
                />
              </Grid>

              <Grid item xs={8} style={{ position: 'relative' }}>
                <InputLabel
                  htmlFor="select-multiple-chip"
                  className={
                    formData.dc_id ? classes.labelShrink : classes.label
                  }>
                  Location
                </InputLabel>

                <Select
                  fullWidth
                  name="dc_id"
                  value={formData.dc_id || ''}
                  onChange={handleChange}
                  input={
                    <OutlinedInput labelWidth={100} id="select-multiple-chip" />
                  }>
                  {dc.map((d) => {
                    return (
                      <MenuItem key={d.dc_id} value={d.dc_id}>
                        {d.dc_nm}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>

              <Grid item xs={8} style={{ position: 'relative' }}>
                <InputLabel
                  htmlFor="select-multiple-chip"
                  className={
                    formData.access ? classes.labelShrink : classes.label
                  }>
                  Access
                </InputLabel>

                <Select
                  fullWidth
                  name="access"
                  value={formData.access || ''}
                  onChange={handleChange}
                  input={
                    <OutlinedInput labelWidth={100} id="select-multiple-chip" />
                  }>
                  {access_options.map((a) => {
                    return (
                      <MenuItem key={a} value={a}>
                        {a}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>

              <Grid item xs={8} style={{ position: 'relative' }}>
                <InputLabel
                  htmlFor="select-multiple-chip"
                  className={
                    formData.roles?.length ? classes.labelShrink : classes.label
                  }>
                  Roles
                </InputLabel>

                <Select
                  fullWidth
                  multiple
                  name="roles"
                  onChange={handleChange}
                  value={formData.roles || []}
                  input={
                    <OutlinedInput labelWidth={100} id="select-multiple-chip" />
                  }
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                          onDelete={() => handleDeleteRole(value)}
                        />
                      ))}
                    </div>
                  )}
                  open={menuOpen}
                  MenuProps={MenuProps}
                  onOpen={() => setMenuOpen(true)}
                  onClose={() => setMenuOpen(false)}>
                  {roles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid
                item
                xs={8}
                style={{
                  textAlign: 'center',
                }}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                  className={classes.submitButton}
                  onClick={submit}>
                  {isCreateDriver ? 'Create' : 'Update'}
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              xs={8}
              style={{
                marginTop: 20,
                padding: 12,

                height: 'fit-content',
                textAlign: 'center',
                ...(isShowPasswordForm && {
                  border: '2px solid #9c27b0',
                  borderRadius: 12,
                }),
              }}>
              <Grid
                container
                spacing={8}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                {isShowPasswordForm ? (
                  <>
                    <Grid item xs={8}>
                      <Typography variant="h5" gutterBottom>
                        Change Password
                      </Typography>
                    </Grid>

                    <Grid item xs={8}>
                      <PasswordInput
                        name="password"
                        label="New Password"
                        id="adornment-password"
                        value={pwData.password}
                        onChange={handlePwChange}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <PasswordInput
                        name="confirmPassword"
                        label="Confirm Password"
                        id="adornment-password"
                        onChange={handlePwChange}
                        value={pwData.confirmPassword}
                      />
                    </Grid>
                    {error && (
                      <Grid item xs={8}>
                        <Typography color="error">{error}</Typography>
                      </Grid>
                    )}
                    <Grid item xs={8}>
                      <Button
                        color="primary"
                        disabled={!!error}
                        variant="contained"
                        className={classes.submitButton}
                        onClick={handlePwSubmit}>
                        Change Password
                      </Button>
                    </Grid>
                  </>
                ) : (
                  !isCreateDriver && (
                    <Grid item xs={8}>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={isSubmitting}
                        onClick={() => setIsShowPasswordForm(true)}>
                        Click to change password
                      </Button>
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
            {!isCreateDriver && (
              <Grid
                item
                xs={8}
                style={{
                  marginTop: 20,
                  padding: 12,
                  border: driverDetail.disabled
                    ? '2px solid red'
                    : '2px solid green',
                  borderRadius: 12,
                  height: 'fit-content',
                  textAlign: 'center',
                }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    color: driverDetail.disabled ? 'red' : 'green',
                  }}>
                  {driverDetail.disabled
                    ? 'Driver is Inactive'
                    : 'Driver is Active'}
                </Typography>

                <Button
                  color="primary"
                  variant="outlined"
                  style={{
                    borderColor: driverDetail.disabled ? 'green' : 'red',
                    color: driverDetail.disabled ? 'green' : 'red',
                    marginTop: 8,
                  }}
                  disabled={isChangingVisibility}
                  onClick={() => {
                    handleVisibilityChange(!driverDetail.disabled);
                  }}>
                  {driverDetail.disabled ? 'Enable' : 'Disable'}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );

  const renderMergeUserPropsForm = (res) => (
    <>
      {res?.setPassword ? (
        renderPasswordMessage(res.setPassword)
      ) : (
        <Grid container spacing={8} className={classes.tabContent}>
          <Grid item xs={4}>
            <GenericUserSearch
              label={null}
              fullWidth={true}
              autoFocus={true}
              placeholder={
                formData._id ? formData._id : `Search for a user here!`
              }
              onUserSelect={(user) => {
                if (!user) {
                  handleChange({
                    target: {
                      name: '_id',
                      value: '',
                    },
                  });
                  return;
                }

                handleChange({
                  target: {
                    name: '_id',
                    value: user._id,
                  },
                });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.is_rt || false}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        name: 'is_rt',
                        value: e.target.checked,
                      },
                    });
                  }}
                  value={'is_rt'}
                />
              }
              label={'Is RT'}
            />
          </Grid>

          <Grid item xs={4} style={{ position: 'relative' }}>
            <InputLabel
              htmlFor="select-multiple-chip"
              className={formData.dc_id ? classes.labelShrink : classes.label}>
              Location
            </InputLabel>

            <Select
              fullWidth
              name="dc_id"
              value={formData.dc_id || ''}
              onChange={handleChange}
              input={
                <OutlinedInput labelWidth={100} id="select-multiple-chip" />
              }>
              {dc.map((d) => {
                return (
                  <MenuItem key={d._id} value={d.dc_id}>
                    {d.dc_nm}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              className={classes.submitButton}
              onClick={createDriverInSch}>
              Create
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );

  const renderPasswordMessage = (pass) => {
    return (
      <>
        <Typography
          variant="h6"
          style={{ padding: '18px 0px', textAlign: 'center' }}>
          Set password is "<b>{pass}</b>"
        </Typography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
          }}>
          <Button
            type="button"
            color="primary"
            variant="outlined"
            onClick={() => {
              setRes(false);
              setFormData({ roles: ['oncall', 'driver'] });
            }}>
            Go Back
          </Button>
        </div>
      </>
    );
  };

  return (
    <Grid container spacing={8}>
      <Grid item xs={8} style={{ display: 'flex', alignSelf: 'center' }}>
        <Button
          type="button"
          color="primary"
          variant="outlined"
          disabled={isSubmitting}
          size="small"
          onClick={() => props.history.push('/admin/dtrack/drivers')}>
          Back to Driver list
        </Button>
      </Grid>
      {isLoading ? (
        <Loading />
      ) : (
        <form className={classes.form} onSubmit={handleSubmit}>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            style={{ borderBottom: '1px solid #e6dede' }}>
            <Tab
              label={
                isCreateDriver ? 'Create Driver Manually' : 'Update Driver'
              }
            />
            {isCreateDriver && <Tab label="Create Driver from a SCH user" />}
          </Tabs>
          <div
            style={{
              padding: '0px 20px',
            }}>
            {activeTab === 0 && renderTabContent(res)}
            {activeTab === 1 && renderMergeUserPropsForm(res)}
          </div>
        </form>
      )}
    </Grid>
  );
};

const mapDispatchToProps = {
  open_snack_ac,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(EditDriverForm));
